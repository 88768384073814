<template>
	<div>
		<a-modal title="发布应用" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<div>
				<a-form-model ref="ruleForm" :model="form" :label-col="{ span:  4}" :wrapper-col="{ span: 20}">
					<a-form-model-item label="模版ID" required >
						<a-input style="width: 408px;" v-model="form.template_id" placeholder="请输入" />
					</a-form-model-item>
					
					<a-form-model-item label="版本号" required >
						<a-input style="width: 408px;" v-model="form.version" placeholder="请输入" />
					</a-form-model-item>
					
					<a-form-model-item label="应用说明" required >
						<a-textarea style="width: 408px;" v-model="form.info" placeholder="请输入" :rows="6"/>
					</a-form-model-item>
				
				</a-form-model>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form:{
					template_id:'',
					version:'',
					info:'',
				}
			}
		},
		methods: {
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				this.$emit("ok");
			},
			
		}
	}
</script>

<style>
	
</style>
